@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&family=Lora:wght@400;500;600&display=swap');
@import 'themes.css';

:root {
  --primary-bg: #f4efe1;
  --burger-bg: #f4efe1;
  --secondary-bg: #e0d8c0;
  --text-color: #3a3a3a;
  --accent-color: #8b7d6b;
  --border-color: #b8a88a;
  --highlight-color: #f0e68c;
  --highlight-color: #ffe066;
  --danger-color: #ff4d4d;
  --placeholder-color: #999;
  --content-font: 'Merriweather', serif;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--primary-bg);
  color: var(--text-color);
  font-size: calc(16px + 0.3vw);
  font-family: var(--content-font);
}

h1, h2, h3 {
  font-family: var(--content-font);
  color: var(--accent-color);
  border-bottom: 2px solid var(--border-color);
  padding-bottom: 0.5em;
  margin-top: 1.5em;
}

select {
  padding: 8px 12px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--primary-bg);
  font-size: 14px;
  color: var(--text-color);
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='%234a3f35' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 50%;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.3s;
}

select:hover {
  border-color: var(--accent-color);
}

select:focus {
  outline: none;
  border-color: var(--accent-color);
  box-shadow: 0 0 0 2px rgba(139, 125, 107, 0.2);
}

button {
  background-color: var(--accent-color);
  color: var(--primary-bg);
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover:not(:disabled) {
  background-color: #6c6156;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  select {
    width: 100%;
  }
}

@font-face {
  font-family: 'OpenDyslexic';
  src: url('./fonts/OpenDyslexic/OpenDyslexic-Regular.otf') format('otf'),
        url('./fonts/OpenDyslexic/OpenDyslexic-Regular.woff') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.dyslexic-font * {
  font-family: 'OpenDyslexic', sans-serif !important;
  font-size: 1rem !important;
}

.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (min-width: 1200px) {
  body {
    font-size: calc(18px + 0.3vw);
  }
}

@media (min-width: 1600px) {
  body {
    font-size: calc(20px + 0.3vw);
  }
}

/* Global custom scrollbar styles */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--accent-color) var(--primary-bg);
}

*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: var(--primary-bg);
  border-radius: 4px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--accent-color);
  border-radius: 4px;
  border: 2px solid var(--primary-bg);
}

*::-webkit-scrollbar-thumb:hover {
  background-color: var(--accent-color);
}

/* Update other elements to use the custom font */
p, div, span, select, input, button {
  font-family: var(--content-font);
}
