.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.books-browser-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 0 !important;
  margin-bottom: 20px;
  padding: 10px;
  border-bottom: 1px solid var(--border-color);
}

.books-browser-title {
  font-size: 18px;
  font-weight: 700;
  color: #4a3f35;
  margin: 0;
  text-align: center;
}

.books-browser-subtitle {
  margin-bottom: 5px;
}

.books-browser {
  width: 350px; /* Default width for larger screens */
  max-width: 100%; /* Ensure it doesn't exceed the container width */
  flex-shrink: 0; /* Prevent shrinking */
  background-color: var(--secondary-bg);
  padding: 20px;
  box-shadow: inset -5px 0 15px rgba(0, 0, 0, 0.1);
  border-right: 1px solid var(--border-color);
  box-sizing: border-box; /* Include padding in width calculation */
  position: relative;
  transition: width 0.3s ease;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: calc(14px + 0.2vw);
}

@media (min-width: 1200px) {
  .books-browser {
    font-size: calc(16px + 0.2vw);
  }
}

.settings-icon,
.profile-icon {
  cursor: pointer;
  transition: transform 0.6s ease;
}

.settings-icon:hover {
  transform: rotate(90deg);
}

.profile-icon:hover {
  transform: rotate(360deg);
}

.profile-icon.active,
.settings-icon.active {
  color: var(--accent-color);
  background-color: rgba(var(--accent-color-rgb), 0.1);
  border-radius: 50%;
  padding: 0px;
  box-shadow: 0 0 0 2px var(--accent-color);
}
.settings-panel {
  padding: 20px;
  margin-top:0;
  padding-top:0;
}

.menu-section {
  margin-bottom: 20px;
}

.menu-section h3 {
  margin-bottom: 10px;
  font-size: 16px;
  color: var(--accent-color);
}

.books-browser.resizing {
  transition: none; /* Disable transition during resizing */
}

.books-filters {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.books-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 400px;
}


.filter-select {
  width: 100%;
  min-width: unset;
  max-width: unset;
  padding-right: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


.language-selectors {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 10px;
}

.language-selector {
  display: flex;
  flex-direction: column;
}

.language-selector label {
  margin-bottom: 5px;
}

.language-selector select {
  width: 100%;
}

.book-title {
  font-size: 1rem;
  font-weight: 700;
  color: #4a3f35;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.book-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  height: 100%; /* Ensure consistent height */
}

.book-card-front {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.book-card-back {
  justify-content: space-between;
  padding: 1rem;
}

.back-button {
  padding: 0.5rem;
  background-color: var(--accent-color);
  color: var(--text-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  width: 100%;
}

.back-button:hover {
  background-color: var(--accent-hover-color);
}

.book-meta {
  margin-top: 10px;
}

.book-summary {
  margin-top: 10px;
}

.book-stats {
  width: 100%;
}

.difficulty-badge {
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
  color: var(--text-color);
}

.word-count {
  font-size: 14px;
  color: #666;
}

/* Custom scrollbar styles */
.books-browser {
  scrollbar-width: thin;
  scrollbar-color: var(--accent-color) var(--primary-bg);
}

.books-browser::-webkit-scrollbar {
  width: 8px;
}

.books-browser::-webkit-scrollbar-track {
  background: var(--primary-bg);
  border-radius: 4px;
}

.books-browser::-webkit-scrollbar-thumb {
  background-color: var(--accent-color);
  border-radius: 4px;
  border: 2px solid var(--primary-bg);
}

.books-browser::-webkit-scrollbar-thumb:hover {
  background-color: var(--highlight-color);
}

.resize-handle {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10px;
  cursor: ew-resize;
  background-color: transparent;
  transition: background-color 0.2s ease;
}

.resize-handle:hover,
.resize-handle:active {
  background-color: rgba(0, 0, 0, 0.1);
}

.resize-handle:hover::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 4px;
  height: 20px;
  background-color: var(--accent-color);
  border-radius: 2px;
}

/* Default (LTR) positioning */
.resize-handle {
  right: -5px;
}

/* RTL positioning */
.resize-handle.rtl {
  left: -5px;
  right: auto;
}


.loading-animation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
}

.favorite-icon {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.favorite-icon:hover {
  transform: scale(1.1);
}

.favorite-icon.favorite {
  animation: heartBeat 0.3s ease-in-out;
}

@keyframes heartBeat {
  0% { transform: scale(1); }
  50% { transform: scale(1.3); }
  100% { transform: scale(1); }
}

.theme-options {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.theme-option {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: transform 0.2s ease;
}

.theme-option:hover {
  transform: scale(1.05);
}

.theme-option.active {
  box-shadow: 0 0 0 2px var(--accent-color);
}

.theme-colors {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.theme-colors div {
  flex: 1;
}

.theme-check {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 2px;
}


.search-bar {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.search-input {
  padding: 12px 16px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-size: 1rem;
  background-color: var(--primary-bg);
  color: var(--text-color);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.search-input:focus {
  outline: none;
  border-color: var(--accent-color);
  box-shadow: 0 0 0 3px rgba(var(--accent-color-rgb), 0.1);
}

.search-input::placeholder {
  color: var(--placeholder-color);
}

@media (max-width: 768px) {

  .resize-handle {
    display: none;
  }

  .parallel-text-container {
      flex-direction: column;
  }

  .books-browser {
      position: fixed;
      top: 0;
      left: 0;
      width: 100% !important;
      max-width: none;
      height: 100%;
      transform: translateX(-100%);
      z-index: 100;
      overflow-x: hidden;
      overflow-y: auto;
  }

  .books-browser.open {
      transform: translateX(0);
  }

  .books-filters {
      flex-direction: column;
      margin: 0 auto;
      margin-bottom: 20px;
  }

  .books-list {
      width: 100%;
  }

  .book-title {
    font-size: 16px;
  }

  .filter-select {
    font-size: 14px;
  }

  .language-selector {
    display: flex;
    flex-direction: column;
  }

  .language-selector label {
    text-align: center;
  }

  .books-browser-subtitle {
    text-align: center;
  }

  .books-browser-header {
    margin-top: 20px !important;
  }

  .books-browser.collapsed {
    transform: translateX(-100%);
  }

}

.book-card.selected {
  border: 3px solid var(--accent-color);
  box-shadow: 0 0 20px rgba(var(--accent-color-rgb), 0.2);
  transform: scale(1.02);
}

.checkout-modal-content {
  text-align: center;
  padding: 20px;
}

.continue-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: var(--button-text-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  min-width: 120px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.continue-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.spinner {
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: var(--button-text-color);
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.book-card.current-book {
  border-left: 4px solid var(--accent-color);
  margin-bottom: 1rem;
}

.book-card.current-book::before {
  content: "Current Book";
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  font-size: 0.8rem;
  color: var(--text-muted);
}

.books-browser.collapsed {
  width: 50px !important;
}

.books-browser.collapsed .books-browser-header {
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 15px 0;
}

.books-browser.collapsed .profile-icon,
.books-browser.collapsed .settings-icon,
.books-browser.collapsed .book-icon {
  margin: 5px 0;
}

.book-icon {
  cursor: pointer;
  transition: transform 0.6s ease;
}

.book-icon:hover {
  transform: rotate(360deg);
}

.book-icon.active {
  color: var(--accent-color);
  background-color: rgba(var(--accent-color-rgb), 0.1);
  border-radius: 50%;
  padding: 0px;
  box-shadow: 0 0 0 2px var(--accent-color);
}

.filter-select.disabled {
  opacity: 0.7;
  cursor: help;
  background-color: var(--background-color-secondary);
}

.book-card.current-book.minimized {
  padding: 10px;
  margin-bottom: 0.5rem;
  border-left: 4px solid var(--accent-color);
}

.book-card.current-book.minimized .book-title {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.book-card.current-book.minimized .book-meta,
.book-card.current-book.minimized .book-summary {
  display: none;
}

.book-card.current-book.minimized .book-stats {
  margin-top: 5px;
}

.book-card.current-book.minimized::before {
  content: attr(data-current-label);
  font-size: 0.7rem;
  background-color: var(--accent-color);
  color: var(--background-color);
  padding: 2px 6px;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: absolute;
  top: -0.5rem;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.book-card.selected::before {
  content: attr(data-selected-label);
  font-size: 0.7rem;
  background-color: var(--accent-color);
  color: var(--background-color);
  padding: 2px 6px;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: absolute;
  top: -0.5rem;
  left: 50%;
  transform: translateX(-50%);
}

.book-card.current-book.minimized h3 {
  border-bottom: none;
  margin-top: 10px;
  margin-bottom: 0;
}

.bookmark-filter {
  display: none;
}

.bookmark-filter-button {
  width: 100%;
  padding: 12px 16px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  background-color: var(--primary-bg);
  color: var(--text-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  transition: all 0.2s ease;
  font-size: 1rem;
}

.bookmark-filter-button:hover {
  border-color: var(--accent-color);
  background-color: rgba(var(--accent-color-rgb), 0.1);
}

.bookmark-filter-button[data-active="true"] {
  background-color: var(--accent-color);
  color: var(--background-color);
  border-color: var(--accent-color);
}

.bookmark-icon {
  position: relative;
  cursor: pointer;
}

.bookmark-icon:not(:disabled):hover::after {
  content: attr(title);
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: var(--accent-color);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1000;
}

.bookmark-filter-button.disabled {
  opacity: 0.6;
  cursor: help;
}

.filter-select option.category-heading {
  font-weight: bold;
  color: white;
  padding: 4px;
}

.filter-select option:not(.category-heading) {
  padding-left: 1em;
  background-color: var(--background-primary);
  color: var(--text-color);
}

.admin-filter-checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  background-color: var(--background-color-secondary);
  border-radius: 4px;
  font-size: 0.9em;
  cursor: pointer;
}

.admin-filter-checkbox input[type="checkbox"] {
  margin: 0;
  cursor: pointer;
}

.delete-button {
  padding: 4px 8px;
  background-color: var(--danger-color, #dc3545);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 8px;
  transition: background-color 0.2s;
}

.delete-button:hover {
  background-color: var(--danger-hover-color, #c82333);
}

.filter-select option {
  font-family: var(--content-font, system-ui);
  padding: 8px;
}

/* Add font preview styles */
.filter-select option[value="arial"] {
  font-family: Arial, sans-serif;
}

.filter-select option[value="helvetica"] {
  font-family: Helvetica, sans-serif;
}

.filter-select option[value="times"] {
  font-family: "Times New Roman", Times, serif;
}

.filter-select option[value="georgia"] {
  font-family: Georgia, serif;
}

.filter-select option[value="verdana"] {
  font-family: Verdana, sans-serif;
}

.setting-item {
  margin-bottom: 16px;
}

.toggle-switch {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 0;
  margin-right: 10px;
  color: var(--text-color);
  font-size: calc(14px + 0.2vw);
  font-family: var(--content-font, system-ui);
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: relative;
  display: inline-block;
  margin-left: 0;
  margin-right: 10px;
  width: 48px;
  height: 24px;
  background-color: #ccc;
  border-radius: 24px;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  border-radius: 50%;
  transition: .4s;
}

.toggle-switch input:checked + .slider {
  background-color: var(--accent-color);
}

.toggle-switch input:checked + .slider:before {
  transform: translateX(24px);
}

.toggle-label {
  user-select: none;
}
