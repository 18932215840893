.admin-toolbar {
    position: fixed;
    top: 0;
    right: 0;
    background: #f0f0f0;
    padding: 10px;
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    z-index: 1000;
  }
  
  .admin-controls {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .content-editor {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    width: 80%;
    max-width: 800px;
  }
  
  .content-editor textarea {
    width: 100%;
    height: 400px;
    margin-bottom: 10px;
    font-family: monospace;
  }
  
  .editor-controls {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .credentials-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    gap: 10px;
  }