h1 {
  text-align: center;

}

h2 {
  text-align: center;

  border-bottom: none;
  margin: 0 !important;
  padding: 0;
}

.theme-picker-welcome-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 1rem;
    box-sizing: border-box;
    overflow-y: none;
  }
  
  .theme-picker-welcome-screen h2 {
    color: #ffffff;
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .theme-options {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 100%;
  }
  
  .theme-option-welcome {
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s ease;
    padding: 0.5em;
    box-sizing: border-box;
  }
  
  .theme-option-welcome:hover {
    transform: scale(1.1);
  }
  
  .theme-option-welcome span {
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    font-size: 1.3rem;
    max-width: 100%;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
  
  .theme-picker-welcome-screen button {
    margin-top: 2rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
  }

  .enter-button {
    background-color: var(--accent-color);
    color: var(--primary-bg);
    border: none;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    width: 50%;
    
    /* Animation properties */
    animation: jump 1s ease-in-out infinite;
  }
  
  @keyframes jump {
    0%, 100% {
      width: 50%;
    }
    20% {
      width: 55%;
    }
    50% {
      width: 55%;
    }
    75% {
      width: 55%;
    }
  }
  
  /* Optional: Pause animation on hover */
  .enter-button:hover {
    animation-play-state: paused;
  }
  
  @media (max-width: 768px) {
    .theme-picker-welcome-screen h2 {
      font-size: 1.5rem;
    }
  
    .theme-options {
      gap: 1rem;
    }
  
    .theme-option-welcome {
      width: 80px;
      height: 80px;
      padding: 0.3em;
    }
  
    .theme-option-welcome span {
      font-size: 0.75rem;
    }
  
    .theme-picker-welcome-screen button {
      font-size: 0.9rem;
      padding: 0.4rem 0.8rem;
    }


    .enter-button {
      width: 100%;
    }
  }