.parallel-text-container {
  display: flex;
  height: 100vh;
  background-image: url('https://www.transparenttextures.com/patterns/wood-pattern.png');
  overflow: visible; /* Allow scrolling on the container */
  font-size: calc(16px + 0.3vw);
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 24px;
  color: #333;
}

.book-card {
  background-color: var(--primary-bg);
  border: 1px solid var(--border-color);
  padding: 15px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  transition: all 0.3s ease;
  cursor: pointer;
}

.book-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0,0,0,0.2);
}

.title-word {
  display: inline-block;
  transition: background-color 0.3s ease;
}

/* .title-word.hovered {
  background-color: rgba(255, 255, 0, 0.3);
  cursor: pointer;
}

.title-word:hover {
  background-color: rgba(255, 255, 0, 0.5);
} */

.book-meta {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.difficulty-badge {
  padding: 3px 8px;
  background-color: var(--accent-color);
  color: var(--primary-bg);
  font-weight: 500;
  font-size: 12px;
}

.word-count {
  font-size: 12px;
  color: #6c6156;
}

.book-summary {
  font-size: 14px;
  color: #5c5248;
  margin-bottom: 10px;
  font-style: italic;
}

.book-stats {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #6c6156;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.toolbar-and-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  background-color: var(--secondary-bg);
  box-shadow: inset 5px 0 15px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.book-content {
  flex-grow: 1;
  background-color: #f9f7f1;
  box-shadow: inset 5px 0 15px rgba(0, 0, 0, 0.1);
  border-top: 2px solid var(--border-color);
  overflow-y: auto;
}

.book-content.welcome-state {
  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-toggle {
  display: none;
  position: fixed;
  top: 10px;
  right: var(--menu-toggle-right);
  left: var(--menu-toggle-left);
  z-index: 1000;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #4a3f35;
  width: 20%;
  margin: 2px;
  background-color: var(--burger-bg);
  border-radius: 5px;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
button.nav-button-left {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

button.nav-button-right {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.book-page-of-total {
  font-size: 18px;

  font-style: italic;
  color: #4a3f35;
  margin-top: 20px;
  text-align: center;
}

.loading-animation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
}

.loading-animation p {
  margin-top: 10px;
  font-size: 18px;
  color: #333;
}

.error-loading-page {
  text-align: center;
  color: red;
  font-size: 18px;
}

@media (max-width: 768px) {
  .parallel-text-container {
      flex-direction: column;
  }

  .books-browser {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translateX(-100%);
      z-index: 100;
  }

  .books-browser.open {
      transform: translateX(0);
  }

  .menu-toggle {
      display: block;
      padding: 0;
      width: 25px;
      flex-shrink: 0;
  }

  .toolbar-and-content {
      width: 100%;
  }

  .book-content {
      width: 100%;
  }
}

.no-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  font-size: 18px;
  font-weight: 700;
  color: #4a3f35;
}

@media (max-width: 768px) {
  .toolbar {
      width: calc(100% - 50px);
      flex-shrink: 0;
      overflow-y: auto;
  }
}

.parallel-text-history {
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  background-color: #fff;
}

p {
  margin: 0;
  padding: 0;
}

.page-navigation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.page-navigation button {
  margin-bottom: 10px;
  margin-top: 10px;
}

.book-page-of-total input {
  margin-right: 5px;
  padding: 5px;
  border: 1px solid var(--border-color);
  background-color: var(--primary-bg);
  color: #dbdbda;
  font-size: 16px;
  text-align: center;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.book-page-of-total input:focus {
  outline: none;
  border-color: var(--accent-color);
  box-shadow: 0 0 5px rgba(74, 63, 53, 0.2);
}

.skip-to-page-button {
  margin-left: 5px;
  margin-right: 5px;
}

.current-page-input {
  width: 3em;
  margin-right: 5px;
  margin-left: 5px;
  color: #4a3f35;
}

.page-jump-form {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.page-jump-form input {
  width: 50px;
  margin-right: 5px;
}

.page-jump-form button {
  padding: 5px 10px;
}

@media (min-width: 1200px) {
  .parallel-text-container {
    font-size: calc(18px + 0.3vw);
  }
}

@media (min-width: 1600px) {
  .parallel-text-container {
    font-size: calc(20px + 0.3vw);
  }
}

/* Add these new styles */
.loading-label {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

.loading-text {
  min-width: max-content;
}

.loading-dots {
  width: 1.5em;  /* Width of 3 dots */
  text-align: inherit; /* Inherit text alignment from parent */
  direction: inherit; /* Inherit direction from parent */
}

.book-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.2rem;
}

.chapter-title {
  font-size: 0.6em;
  color: var(--text-secondary);
  font-weight: normal;
  text-align: center;
  margin-top: -5px;
}

.nav-button-wrapper {
  position: relative;
  overflow: hidden;
}

.nav-progress-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  transition: width 16ms linear;
  pointer-events: none;
}

.nav-cooldown {
  opacity: 0.8;
}

@keyframes countdown {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -31.4; /* Approximately -2 * π * 5 (circle radius) */
  }
}

.navigation-buttons button.debounced {
  opacity: 0.7;
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Ccircle cx='10' cy='10' r='5' fill='none' stroke='%23000' stroke-width='2' stroke-dasharray='31.4' stroke-dashoffset='0' style='animation: countdown 1s linear;'/%3E%3C/svg%3E") 10 10, not-allowed;
  transition: opacity 0.2s ease-in-out;
}

/* Add a fallback cursor for browsers that might not support the SVG cursor */
@supports not (cursor: url("data:image/svg+xml,...")) {
  .navigation-buttons button.debounced {
    cursor: wait;
  }
}

.choose-book-prompt {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1.5em;
  color: var(--text-secondary);
  text-align: center;
  padding: 2rem;
}

.choose-book-prompt .clickable-welcome-text {
  text-decoration: underline;
}

.clickable-welcome-text {
  color: var(--accent-color);
  cursor: pointer;
  transition: color 0.2s ease;
}

.clickable-welcome-text:hover {
  color: var(--accent-color-hover);
}

/* Only show hover effects on non-touch devices */
@media (hover: hover) {
  .clickable-welcome-text:hover {
    opacity: 0.8;
  }
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .clickable-welcome-text {
    display: inline-block;
    padding: 2px 4px;
    position: relative;
  }
  
  .clickable-welcome-text::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--accent-color);
    transform: scaleX(0);
    transition: transform 0.2s ease;
  }
  
  .clickable-welcome-text:active::after {
    transform: scaleX(1);
  }
}
