.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 100%;
  background-color: var(--secondary-bg);
  box-sizing: border-box;
  font-size: calc(14px + 0.2vw);
}

.cursor-mode-button {
  background-color: var(--secondary-bg);
  border: 1px solid var(--border-color);
  padding: 5px 15px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: black;
}

.cursor-mode-button.selected {
  background-color: var(--accent-color);
  color: var(--primary-bg);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.native-language-toggle,
.pronunciation-toggle,
.furigana-toggle,
.report-issue,
.bookmark-toggle {
  background-color: var(--secondary-bg);
  border: 1px solid var(--border-color);
  padding: 5px;  /* Simplified padding */
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: black;
  height: 32px;  /* Fixed height for all buttons */
  width: 32px;   /* Fixed width for square buttons */
  display: flex;
  align-items: center;
  justify-content: center;
}

.native-language-toggle.enabled, 
.pronunciation-toggle.enabled,
.furigana-toggle.enabled,
.bookmark-toggle.enabled {
  background-color: var(--accent-color);
  color: var(--primary-bg);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.native-language-toggle:disabled,
.pronunciation-toggle:disabled,
.furigana-toggle:disabled,
.report-issue:disabled,
.bookmark-toggle:disabled {
  opacity: 0.3;
  cursor: not-allowed;
  background-color: var(--secondary-bg);
  transform: none;
  box-shadow: none;
}

.text-association-selector {
    width: 100%;
    text-align: left;
}

select {
  appearance: none;
  background-size: 20px 20px;
  background-position: 99% 50%;
  background-repeat: no-repeat;
}

@media (max-width: 768px) {
    .toolbar {
        align-items: flex-start;
        gap: 10px;
        padding: 10px;
        width: 80%;
        box-sizing: border-box;
    }
    .text-association-selector {
        width: 100%;
        text-align: left;
    }

    .native-language-toggle { 
        text-align: left;
    }

    .native-language-toggle.enabled {
        text-align: left;
    }

    button {
      text-align: center !important;
    }
        
}

@media (min-width: 1200px) {
  .toolbar {
    font-size: calc(16px + 0.2vw);
  }
}

.premium-required {
  opacity: 0.7;
  cursor: help !important;
  background-color: var(--background-color-secondary);
}

.premium-required:hover {
  cursor: help !important;
}

.premium-feature {
  opacity: 0.7;
  cursor: help;
  background-color: var(--background-color-secondary) !important;
  border-color: var(--border-color) !important;
  color: var(--text-color) !important;
  position: relative;
}

.premium-feature:not(:disabled):hover::after {
  content: "?";
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: var(--accent-color);
  color: white;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
}

.share-button {
  background-color: var(--secondary-bg);
  border: 1px solid var(--border-color);
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: black;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.share-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.share-buttons .share-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 4px;
  width: auto;
  height: auto;
}

.share-button.twitter { background-color: #1DA1F2; color: white; }
.share-button.facebook { background-color: #4267B2; color: white; }
.share-button.linkedin { background-color: #0077B5; color: white; }
.share-button.whatsapp { background-color: #25D366; color: white; }
.share-button.copy { background-color: var(--secondary-bg); }

.bookmark-toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--toast-background, rgba(0, 0, 0, 0.8));
  color: var(--toast-text, white);
  padding: 10px 20px;
  border-radius: 4px;
  z-index: 1000;
  animation: fadeInOut 2s ease-in-out;
}

@keyframes fadeInOut {
  0% { opacity: 0; transform: translate(-50%, 20px); }
  15% { opacity: 1; transform: translate(-50%, 0); }
  85% { opacity: 1; transform: translate(-50%, 0); }
  100% { opacity: 0; transform: translate(-50%, -20px); }
}

.text-size-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--toolbar-button-bg);
  border: none;
  border-radius: 4px;
  padding: 8px;
  height: 32px;
  width: 32px;
  cursor: pointer;
  color: var(--toolbar-button-color);
  transition: background-color 0.2s ease;
}

.text-size-button:hover {
  background: var(--toolbar-button-hover-bg);
}

.text-size-button:active {
  background: var(--toolbar-button-active-bg);
}

.text-size-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Add some spacing between the buttons */
.text-size-button + .text-size-button {
  margin-left: 4px;
}