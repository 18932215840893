@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&family=Lora:wght@400;500;600&display=swap');

.cookie-notice-overlay {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.cookie-notice {
  background-color: var(--secondary-bg, #e0d8c0);
  color: var(--text-color, #3a3a3a);
  padding: 20px;
  border-radius: 8px;
  max-width: 350px;
  width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Lora', serif;
  font-size: 14px;
  line-height: 1.4;
}

.cookie-notice h2 {
  font-family: 'Merriweather', serif;
  color: var(--accent-color, #8b7d6b);
  border-bottom: 2px solid var(--border-color, #b8a88a);
  padding-bottom: 0.5em;
  margin-top: 0;
  font-size: 18px;
}

.cookie-notice button {
  margin: 10px 10px 0 0;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: var(--accent-color, #8b7d6b);
  color: var(--primary-bg, #f4efe1);
  cursor: pointer;
  font-family: 'Lora', serif;
  font-size: 14px;
  transition: background-color 0.3s;
}

.cookie-notice button:hover {
  background-color: #6c6156;
}

.cookie-preferences {
  margin-top: 15px;
}

.cookie-preferences label {
  display: block;
  margin: 10px 0;
  font-size: 14px;
}

.cookie-preferences input[type="checkbox"] {
  margin-right: 8px;
}

.cookie-notice-footer {
  margin-top: 15px;
  font-size: 12px;
  border-top: 1px solid var(--border-color, #b8a88a);
  padding-top: 10px;
}

.cookie-notice-footer a {
  color: var(--accent-color, #8b7d6b);
  text-decoration: none;
  margin-right: 10px;
  transition: color 0.3s;
}

.cookie-notice-footer a:hover {
  color: #6c6156;
  text-decoration: underline;
}

.dyslexic-font .cookie-notice * {
  font-family: 'OpenDyslexic', sans-serif !important;
}

@media (max-width: 480px) {
  .cookie-notice-overlay {
    bottom: 10px;
    right: 10px;
    left: 10px;
  }

  .cookie-notice {
    max-width: none;
  }
}