.profile-panel {
  background-color: var(--background-color);
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin-top: 16px;
  width: 100%;
  box-sizing: border-box;
  text-align: center !important;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: inherit; /* Use the default font family */
  font-size: calc(16px + 0.3vw);
}

.profile-panel h3 {
  font-size: 1.5rem;
  color: var(--text-color);
  margin-bottom: 24px;
  margin-top: 0;
  text-align: center;
  font-weight: 600;
}

.profile-panel form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.profile-panel input {
  padding: 12px 16px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  box-sizing: border-box;
  font-family: inherit; /* Use the default font family */
}

.profile-panel input:focus {
  outline: none;
  border-color: var(--accent-color);
  box-shadow: 0 0 0 3px rgba(var(--accent-color-rgb), 0.1);
}

.profile-panel button {
  padding: 12px 16px;
  background-color: var(--accent-color);
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
  width: 100%;
  font-family: inherit; /* Use the default font family */
}

.profile-panel button:hover {
  background-color: var(--accent-color-dark);
}

.profile-panel button:active {
  transform: translateY(1px);
}

.profile-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.profile-info-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* // use ellipsis if it overflow */
.username-section,
.email-section {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.username-section label,
.email-section label {
  font-weight: 600;
  color: var(--text-color);
}

.email-label {
  margin-bottom: 10px;
}

.email-address {
  margin-bottom: 10px;
  font-size: 1rem;
}

.email-change-buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
}

.password-reset-buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
}

.password-input-container {
  position: relative;
}

.toggle-password-visibility {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: var(--accent-color);
  border-radius: 6px;
  width: 30px;
  height: 30px;
  color: white;
  justify-content: center;
}

.password-input-container input {
  padding-right: 30px;
}

.username-section > div {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.username-section input {
  flex: 1;
  min-width: 0;
}

.username-section button {
  flex: 0 0 auto;
  width: auto;
  min-width: 80px;
}

.set-username-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
}

.set-username-button span {
  margin-right: 8px;
  font-size: 1.2rem;
}

.profile-picture-section {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.profile-picture-section img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid var(--accent-color);
}

.subscription-section {
  margin-top: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.current-subscription {
  background-color: var(--background-color-light);
  border: 1px solid var(--border-color);
  border-radius: 6px;
  margin-bottom: 8px;
  max-width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

.current-subscription p {
  margin: 8px 0;
  color: var(--text-color);
  font-size: 1rem;
  padding: 0 8px;
  white-space: normal;
}

.subscription-button, .subscription-learn-more {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

.subscription-info {
  margin-top: 10px;
}

.logout-section {
  display: flex;
  justify-content: center;
}

.logout-button:hover {
  background-color: var(--danger-color-dark);
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.login-button {
  margin-bottom: 10px;
}

.status-message {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 0.9rem;
}

.status-message.error {
  background-color: rgba(var(--danger-color-rgb), 0.1);
  color: var(--danger-color);
}

.status-message.success {
  background-color: rgba(var(--success-color-rgb), 0.1);
  color: var(--success-color);
}

.subscribe-notice {
  margin-top: 16px;
  color: var(--text-color-muted);
  font-size: 0.9rem;
  text-align: center;
}

.logout-button {
  background-color: var(--danger-color);
  color: white;
}

.form-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  gap: 12px;
  margin-top: 20px;
}

.toggle-signup,
.reset-password-button {
  background-color: transparent;
  color: var(--text-color);
  padding: 8px;
  font-size: 0.9rem;
  font-weight: 500;
  text-decoration: underline;
  border: none;
  width: auto;
  cursor: pointer;
  transition: color 0.3s ease;
  font-family: inherit; /* Use the default font family */
}

.toggle-signup:hover,
.reset-password-button:hover {
  color: var(--accent-color);
  background-color: transparent;
}

.error-message {
  color: var(--danger-color);
  font-size: 0.9rem;
  margin-top: 16px;
  text-align: center;
  padding: 8px;
  background-color: rgba(var(--danger-color-rgb), 0.1);
  border-radius: 4px;
  font-family: inherit; /* Use the default font family */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .profile-panel {
    padding: 20px;
  }

  .profile-panel h3 {
    font-size: 1.3rem;
    margin-bottom: 20px;
  }

  .profile-panel input,
  .profile-panel button {
    padding: 10px 14px;
    font-size: 0.95rem;
  }

  .username-section,
  .email-section {
    flex-direction: column;
    align-items: flex-start;
  }

  .username-section > div {
    flex-direction: column;
    width: 100%;
  }

  .username-section button {
    width: 100%;
  }

  .form-footer {
    gap: 10px;
    margin-top: 16px;
  }

  .current-subscription {
    padding: 12px;
  }

  .current-subscription p {
    font-size: 0.9rem;
  }

}

@media (min-width: 1200px) {
  .profile-panel {
    font-size: calc(18px + 0.3vw);
  }
}

.checkout-modal-content {
  text-align: center;
  padding: 20px;
}

.continue-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: var(--button-text-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  min-width: 120px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.continue-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.spinner {
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: var(--button-text-color);
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.trial-ended-message {
  color: #ff6b6b;
  margin-bottom: 1rem;
  font-weight: 500;
}

.google-sign-in {
  margin-bottom: 20px;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.google-sign-in > div {
  justify-content: center;
}

.google-sign-in iframe {
  margin: 0 auto !important;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #ddd;
}

.separator span {
  padding: 0 10px;
  color: #666;
  font-size: 14px;
}
