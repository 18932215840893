/* Modal.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: var(--primary-bg);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 600px;
    max-height: 80vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    font-family: 'Lora', serif;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px 10px;
    position: sticky;
    top: 0;
    background-color: var(--primary-bg);
    z-index: 10;
  }
  
  .modal-header h2 {
    margin: 0;
    font-size: 28px;
    color: var(--accent-color);
    font-family: 'Merriweather', serif;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: var(--accent-color);
    padding: 0;
  }
  
  .modal-body {
    padding: 20px;
    overflow-y: auto;
    text-align: center;
  }
  
  /* Button styles */
  .modal-button {
    background-color: var(--accent-color);
    color: var(--primary-bg);
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    margin-right: 10px;
    margin-top: 10px;
  }
  
  .modal-button:hover {
    background-color: var(--highlight-color);
  }
  
  .modal-button:last-child {
    margin-right: 0;
  }
  
  /* Custom scrollbar styles */
  .modal-content {
    scrollbar-width: thin;
    scrollbar-color: var(--accent-color) var(--primary-bg);
  }
  
  .modal-content::-webkit-scrollbar {
    width: 8px;
  }
  
  .modal-content::-webkit-scrollbar-track {
    background: var(--primary-bg);
    border-radius: 4px;
  }
  
  .modal-content::-webkit-scrollbar-thumb {
    background-color: var(--accent-color);
    border-radius: 4px;
    border: 2px solid var(--primary-bg);
  }
  
  .modal-content::-webkit-scrollbar-thumb:hover {
    background-color: var(--highlight-color);
  }
  
  @media (max-width: 480px) {
    .modal-overlay {
      align-items: flex-start;
    }
  
    .modal-content {
      width: 100%;
      height: 100vh;
      max-height: none;
      border-radius: 0;
    }
  
    .modal-header {
      padding: 10px;
      height: 60px;
    }
  
    .modal-header h2 {
      font-size: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  
    .modal-body {
      flex: 1;
      overflow-y: auto;
      padding: 10px;
      height: calc(100vh - 60px); /* Subtract header height */
    }
  }
  
  /* Ensure smooth scrolling on iOS devices */
  @supports (-webkit-overflow-scrolling: touch) {
    .modal-body {
      -webkit-overflow-scrolling: touch;
    }
  }

.modal-footer {
  padding: 20px;
  text-align: center;
  background-color: var(--primary-bg);
  border-top: 1px solid var(--accent-color);
}

.modal-close-button {
  min-width: 120px;
  margin: 0;
}

@media (max-width: 480px) {
  .modal-footer {
    padding: 10px;
    position: sticky;
    bottom: 0;
  }
}