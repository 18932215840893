@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

:root {
  --primary-bg: #f4efe1;
  --burger-bg: #f4efe1;
  --secondary-bg: #e0d8c0;
  --text-color: #3a3a3a;
  --accent-color: #8b7d6b;
  --border-color: #b8a88a;
  --highlight-color: #f0e68c;
  --bookmark-color: #8b7d6b;
  --text-muted-color: #999;
  font-family: 'Merriweather', serif;
}

[data-theme="dark"] {
  --primary-bg: #1a1a2e;
  --burger-bg: #ffffff;
  --secondary-bg: #16213e;
  --text-color: #ffffff;
  --accent-color: #e94560;
  --border-color: #0f3460;
  --highlight-color: #e94560;
  --bookmark-color: #6b7d8b;
  --header-color: #ffffff; /* New variable for header text color */
  font-family: 'Poppins', sans-serif;
}

[data-theme="vibrant"] {
  --primary-bg: #ffffff;
  --burger-bg: #ffffff;
  --secondary-bg: #f0f0f0;
  --text-color: #333333;
  --accent-color: #ff6b6b;
  --border-color: #ff9ff3;
  --highlight-color: #feca57;
}

/* Dark theme specific styles */
[data-theme="dark"] .parallel-text-container {
  background-color: var(--primary-bg);
}

[data-theme="dark"] .stories-browser {
  background-color: var(--secondary-bg);
  box-shadow: 5px 0 15px rgba(0, 0, 0, 0.3);
}

[data-theme="dark"] .filter-select {
  background-color: var(--border-color);
  color: var(--text-color);
}

[data-theme="dark"] .story-card {
  background-color: var(--border-color);
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

[data-theme="dark"] .story-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
}

[data-theme="dark"] .story-title {
  color: var(--header-color); /* Updated to use the new header color variable */
  font-weight: 600; /* Make headers bolder */
}

[data-theme="dark"] button {
  color: var(--text-color);
}

[data-theme="dark"] p {
  color: var(--text-color);
}

[data-theme="dark"] h1, [data-theme="dark"] h2, [data-theme="dark"] h3, 
[data-theme="dark"] h4, [data-theme="dark"] h5, [data-theme="dark"] h6 {
  color: var(--header-color);
  font-weight: 600;
}

[data-theme="dark"] .difficulty-badge {
  background-color: var(--accent-color);
  color: var(--text-color);
  border-radius: 20px;
}

[data-theme="dark"] .word-count,
[data-theme="dark"] .story-stats {
  color: #a0a0a0;
}

[data-theme="dark"] .story-summary {
  color: #c0c0c0;
}

[data-theme="dark"] .pagination-button,
[data-theme="dark"] .navigation-buttons button {
  background-color: var(--accent-color);
  color: var(--text-color);
}

[data-theme="dark"] .pagination-button:hover:not(:disabled),
[data-theme="dark"] .navigation-buttons button:hover:not(:disabled) {
  background-color: var(--highlight-color);
}

[data-theme="dark"] .navigation-buttons button:disabled {
  background-color: #5c2936;
}

[data-theme="dark"] .menu-toggle {
  color: var(--accent-color);
}

[data-theme="dark"] .book-content {
  color: var(--text-color);
  background-color: var(--primary-bg);
}

[data-theme="dark"] div {
  color: var(--text-color);
}


[data-theme="dark"] span {
  color: var(--text-color);
}

@media (max-width: 768px) {
  [data-theme="dark"] .stories-browser {
    background-color: var(--secondary-bg);
  }
}