.loading-progress-container {
    width: 100%;
    margin: 10px 0;
    position: relative;
  }
  
  .loading-progress-label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    padding: 5px;
    color: var(--text-color);
    font-size: 0.9em;
    white-space: nowrap;
  }
  
  .loading-progress-bar { 
    width: 100%;
    height: 1.5em;
    background-color: var(--accent-color);
    border-radius: 2px;
    overflow: hidden;
    position: relative;
  }
  
  .loading-progress-bar::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: var(--progress, 0%);
    background-color: var(--primary-color);
    transition: width 0.3s ease-in-out;
  }