.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: var(--primary-bg);
    color: var(--text-color);
    padding: 24px;
    border-radius: 4px;
    width: 80%;
    max-width: 500px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .modal-content h2 {
    color: var(--accent-color);
    border-bottom: 2px solid var(--border-color);
    padding-bottom: 0.5em;
    margin-top: 0;
    margin-bottom: 16px;
  }
  
  .modal-content p {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  
  .modal-content form {
    display: flex;
    flex-direction: column;
  }
  
  .modal-content textarea {
    width: 100%;
    margin-bottom: 16px;
    padding: 8px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background-color: var(--primary-bg);
    color: var(--text-color);
    resize: vertical;
    min-height: 100px;
    box-sizing: border-box;
  }
  
  .modal-content textarea:focus {
    outline: none;
    border-color: var(--accent-color);
    box-shadow: 0 0 0 2px rgba(139, 125, 107, 0.2);
  }
  
  .modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }
  
  .modal-actions button {
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .cancel-button {
    background-color: var(--secondary-bg);
    color: var(--text-color);
    border: 1px solid var(--border-color);
  }
  
  .cancel-button:hover:not(:disabled) {
    background-color: var(--border-color);
  }
  
  .submit-button {
    background-color: var(--accent-color);
    color: var(--primary-bg);
    border: none;
  }
  
  .submit-button:hover:not(:disabled) {
    background-color: var(--highlight-color);
  }
  
  .submit-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  /* Dark theme specific styles */
  [data-theme="dark"] .modal-content {
    background-color: var(--secondary-bg);
  }
  
  [data-theme="dark"] .modal-content h2 {
    color: var(--header-color);
  }
  
  [data-theme="dark"] .modal-content textarea {
    background-color: var(--border-color);
  }
  
  [data-theme="dark"] .cancel-button {
    background-color: var(--border-color);
    color: var(--text-color);
  }
  
  [data-theme="dark"] .cancel-button:hover:not(:disabled) {
    background-color: var(--accent-color);
  }
  
  [data-theme="dark"] .submit-button {
    background-color: var(--accent-color);
    color: var(--text-color);
  }
  
  [data-theme="dark"] .submit-button:hover:not(:disabled) {
    background-color: var(--highlight-color);
  }
  
  /* Dyslexic font override */
  .dyslexic-font .modal-content,
  .dyslexic-font .modal-content h2,
  .dyslexic-font .modal-content textarea,
  .dyslexic-font .modal-actions button {
    font-family: 'OpenDyslexic', sans-serif !important;
  }

  /* Mobile Optimization */
@media screen and (max-width: 768px) {
    .modal-content {
      width: 90%;
      padding: 20px;
      max-height: 80vh;
      overflow-y: auto;
    }
  
    .modal-content h2 {
      font-size: 1.5rem;
      margin-bottom: 12px;
    }
  
    .modal-content p {
      font-size: 0.9rem;
      margin-bottom: 12px;
    }
  
    .modal-content textarea {
      min-height: 80px;
    }
  
    .modal-actions {
      flex-direction: column;
      gap: 8px;
    }
  
    .modal-actions button {
      width: 100%;
      padding: 12px;
      font-size: 1rem;
    }
  }
  
  @media screen and (max-width: 480px) {
    .modal-content {
      width: 95%;
      padding: 16px;
    }
  
    .modal-content h2 {
      font-size: 1.3rem;
    }
  
    .modal-content p {
      font-size: 0.85rem;
    }
  
    .modal-content textarea {
      font-size: 0.9rem;
    }
  }
  
  /* Adjust for devices in landscape orientation */
  @media screen and (max-height: 500px) and (orientation: landscape) {
    .modal-content {
      max-height: 90vh;
    }
  
    .modal-content textarea {
      min-height: 60px;
    }
  
    .modal-actions {
      flex-direction: row;
    }
  }

  .sentiment-selector {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .sentiment-button {
    background: none;
    border: 2px solid transparent;
    border-radius: 50%;
    padding: 0.5rem;
    font-size: 1.5rem;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .sentiment-button:hover {
    transform: scale(1.1);
  }

  .sentiment-button.selected {
    background: var(--accent-color);
  }  @keyframes bounce {
    0%, 100% { transform: translateY(0); }
    10%, 30%, 50%, 70%, 90% { transform: translateY(-4px); }
    20%, 40%, 60%, 80% { transform: translateY(4px); }
  }

  .sentiment-selector.shake {
    animation: bounce 0.8s cubic-bezier(.36,.07,.19,.97) both;
  }
