.parallel-text-history {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Merriweather', serif;
  line-height: 1.6;
  color: #4a3f35;
  font-size: calc(14px + 0.4vw);
}

.sentence-pair {
  display: flex;
  justify-content: space-between;
}

.sentence-pair:nth-child(odd) {
  background-color: var(--secondary-bg, #f9f7f1);
}

.sentence-pair:nth-child(even) {
  background-color: var(--primary-bg, #ffffff);
}

.language-one,
.language-two {
  flex: 1;
  padding: 0.5em;
  position: relative;
}

.language-one {
  border-right: 1px solid var(--border-color, #ccc);
}

.language-two {
  border-left: 1px solid var(--border-color, #ccc);
}

.sentence {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  position: relative;
}

.sentence.has-pronunciation {
  margin-bottom: 0.5em;
}

.word-wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  vertical-align: baseline;
}

.word-wrapper.has-pronunciation {
  margin-bottom: 0.5em;
}

.word-wrapper.toggled {
  background-color: #f98aff;
  border-radius: 4px;
  padding: 0.1em 0.3em;
  transition: background-color 0.3s ease;
}

.word-wrapper.toggled:hover {
  background-color: #ffbebe;
}

.punctuation {
  align-self: flex-end;
  margin-right: 0.1em;
}

.word {
  text-align: center;
  padding: 0.1em 0.2em;
  line-height: 1.2;
  font-weight: normal !important;
}

.pronunciation {
  font-size: calc(0.7em + 0.1vw);
  color: #666;
  text-align: center;
  padding: 0.1em 0.2em;
  margin-bottom: 0.2em;
  white-space: nowrap;
}

.highlight-wrapper {
  background-color: var(--highlight-color, yellow);
  border-radius: 2px;
  transition: background-color 0.3s ease;
}

.highlight-wrapper:hover {
  background-color: var(--highlight-color, #ffff00);
}

.parallel-text-history.dyslexic-font {
  font-family: 'OpenDyslexic', sans-serif;
}

/* Justification styles */
.parallel-text-history.justify-left .sentence { justify-content: flex-start; }
.parallel-text-history.justify-right .sentence { justify-content: flex-end; }
.parallel-text-history.justify-center .sentence { justify-content: center; }

/* Interlace styles */
.parallel-text-history.interlace .sentence-pair {
  flex-direction: column;
}

.parallel-text-history.interlace .language-one,
.parallel-text-history.interlace .language-two {
  border: none;
  padding: 0.25em 0.5em;
}

/* Furigana styles */
.furigana-text {
  font-size: calc(0.6em + 0.1vw);
  line-height: 1;
  text-align: center;
  margin-bottom: 0.2em;
  color: #666;
  transition: opacity 0.3s ease;
}

.notification-popup {
  font-size: 14px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #333;
  color: white;
  padding: 12px 24px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  animation: slideIn 0.3s;
  max-width: 300px;
}

@keyframes slideIn {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@media (max-width: 768px) {
  .sentence-pair {
    flex-direction: column;
  }
  
  .language-one,
  .language-two {
    margin-right: 0;
    border: none;
  }

  /* Remove background from sentence pairs */
  .sentence-pair:nth-child(odd),
  .sentence-pair:nth-child(even) {
    background-color: transparent;
  }

  /* Add alternating backgrounds to languages instead */
  .sentence-pair .language-one {
    background-color: var(--secondary-bg, #f9f7f1);
  }

  .sentence-pair .language-two {
    background-color: var(--primary-bg, #ffffff);
  }
}

@media (min-width: 1200px) {
  .parallel-text-history {
    max-width: 80%;
  }
}

.popup-close-button {
  position: absolute;
  top: 4px;
  right: 4px;
  background: none;
  border: none;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  padding: 4px 8px;
  line-height: 1;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.popup-close-button:hover {
  opacity: 1;
}

/* Add these styles */
.sentence-skeleton {
  display: flex;
  gap: 8px;
  padding: 8px 0;
  flex-wrap: wrap;
}

.word-skeleton,
.pronunciation-skeleton,
.furigana-skeleton {
  background: linear-gradient(
    90deg,
    #f0f0f0 0%,
    #e0e0e0 50%,
    #f0f0f0 100%
  );
  background-size: 200% 100%;
  animation: shimmer 2s infinite linear;
  border-radius: 4px;
}

.word-skeleton {
  height: 1em;
  min-width: 40px;
}

.pronunciation-skeleton {
  height: 0.7em;
  width: 80%;
  margin-top: 4px;
}

.furigana-skeleton {
  height: 0.6em;
  width: 60%;
  margin-bottom: 4px;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.popup-timer-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  background-color: #007bff;
  transition: width 100ms linear;
  width: 80px;
}

/* Add this new style */
.punctuation.whitespace {
  display: inline-block;
  width: 0.2em;  /* or whatever spacing you want */
  height: 1em;   /* maintain line height */
}