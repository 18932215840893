.share-button {
  background-color: var(--secondary-bg);
  border: 1px solid var(--border-color);
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: black;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.share-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
}

.share-buttons .share-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
  border-radius: 4px;
  width: 100%;
  height: auto;
  justify-content: flex-start;
}

.share-button.twitter {
  background-color: #000000;
}

.share-button.facebook { background-color: #4267B2; color: white; }
.share-button.linkedin { background-color: #0077B5; color: white; }
.share-button.whatsapp { background-color: #25D366; color: white; }
.share-button.copy { background-color: var(--secondary-bg); }

.modal-close {
  padding: 4px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  width: 90%;
  height: auto;
  max-width: 320px;
  padding: 20px;
}

.modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
}
